import * as React from "react"
import PropTypes from "prop-types"
import header from '../images/messina-messenger-header.png'


const Header = ({ siteTitle }) => (
  <header className="main-header">
    <img src={header} />
  
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
